.bar{
    width: 35rem;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    position: relative;
}

.pbar {
    margin: 3%;
}

.pbar h5{
    font-size: 1.5rem;
    font-family: 'Segoe UI';
    font-weight: 600;
    text-transform: uppercase;
    margin: 1%;
}

@media screen and (max-width:500px){
    .bar{
        width:200px;
    }
}