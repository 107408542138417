html {
    scroll-behavior: smooth;
  }

.filter
{
    width: 80%;
    padding: 5%;
}

.inputbox{
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.inputbox .input {
    width: 25.5rem;
    box-sizing: border-box;
    border: 2px solid #747474;
    border-radius: 4px;
    font-size: 1.4rem;
    background-repeat: no-repeat;
    padding: 1rem 0.8rem;
    text-align: left;
    background-color: #F1F1F1;
    font-family: Arial, Helvetica, sans-serif;
}

.inputbox .label {
    padding: 2px 0;
    font-size: 14px;
    color: black;
    pointer-events: none;
    transition: .5s;
}

.box{
    width: 90%;
    margin: 2rem auto;
    padding: 5rem;
    display: flex;
    flex-direction: row;
    gap: 3rem;
    flex-wrap:wrap;
    border-radius: 20px;
    justify-content: center;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.filterdrop h1{
    width: 60%;
    margin: auto;
    text-align: center;
    font-family: Helvetica, sans-serif;
    font-size: 5rem;
    margin-bottom: 2rem;
    color: #0A283D;
}

.f {
    width: 80%;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 5rem;
    display: flex;
    gap: 5rem;
}

.viewProfile .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.controlBtn{
    background-color: #F1F1F1;
    border: none;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    color: rgba(105, 102, 102, 0.452);
    display: flex;
    justify-content: center;
    align-items: center;
}

.controlBtn:hover{
    background-color: rgba(105, 102, 102, 0.452);
    color: white;
    border: rgba(105, 102, 102, 0.452);
}

.viewPersonalityHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    align-items: center;
    margin-block: 2rem;
    padding-inline: 1rem;
}

.viewPersonalityHeader h1{
    font-size: 2rem;
    font-family: 'Segoe UI';
    font-weight: 500;
    color: #0A283D;
}

.filterHeader{
    width: 60%;
    margin: auto;
    text-align: center;
    font-family: Helvetica, sans-serif;
    font-size: 3rem;
    margin-bottom: 2rem;
    color: #0A283D;
}