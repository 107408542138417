html {
  scroll-behavior: smooth;
}

.main {
  width: 80%;
  padding: 5%;
}

.main h1 {
  width: 60%;
  margin: auto;
  text-align: center;
  font-family: Helvetica, sans-serif;
  font-size: 5rem;
  margin-bottom: 2rem;
  color: #0a283d;
}

.main h3 {
  text-align: left;
  width: fit-content;
  margin-left: 10%;
  margin-top: 5rem;
  margin-bottom: 4rem;
  font-size: 2.7rem;

  border-bottom: 3px solid transparent;
  border-image: linear-gradient(45deg, #17289c 5%, white 95%);
  border-image-slice: 1;
}

.main .info {
  width: 70%;
  padding: 4rem;
  margin: auto;
  margin-bottom: 5rem;
  font-size: 2.2rem;
  background-color: #f1f1f1;
  border-radius: 2rem;

  margin-bottom: 2rem;
  font-weight: 600;
  color: #0a283d;
}

.main .info form {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-block: 2rem;
}

.main .info form label {
  display: flex;
  flex-direction: column;
}

.main .info form label p {
  font-size: 2rem;
  font-weight: 200;
  margin-bottom: 1rem;
  color: #0a283d;
}

.main .info form label input {
  font-size: 2rem;
  font-weight: 100;
  padding: 0.2rem;
  color: #0a283d;
}

.urlLink {
  width: 100%;
}
.urlLink h1 {
  font-family: Helvetica, sans-serif;
  font-size: 1.5rem;
  margin-block: 1rem;
  margin-left: 0;
  color: #0a283d;
  font-weight: 200;
  text-align: left;
}

.control {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.control .controlBtn {
  background-color: rgba(105, 102, 102, 0.452);
  border: none;
  border-radius: 0.2rem;
  padding: 0.5rem;
  width: fit-content;
  height: fit-content;
  color: white;
}

.resetBtn {
  background-color: rgba(105, 102, 102, 0.452);
  border: none;
  border-radius: 0.2rem;
  padding: 0.5rem;
  width: fit-content;
  height: fit-content;
  color: white;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 300;
  padding-inline: 1rem;
}

.resetBtn:hover {
  background-color: rgba(105, 102, 102, 0.87);
}

@media screen and (max-width: 750px) {
  .main .info {
    width: 80%;
}
.main .info form {
    padding-inline: 2rem;
    flex-direction: column;
    gap:1rem;
    justify-content: center;
    align-items: center;
  }
}
