.body{
    display: flex;
    justify-content: space-between;
    width: 65%;
    margin: auto;
    border-radius: .5rem;
    padding: 1rem 1rem;
    margin-bottom: 2rem;
    background-color: rgba(241, 241, 241, 0.326);
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    border-top:1rem solid #0A283D;
}

.body:hover{
    cursor: pointer; 
    background-color: rgba(241, 241, 241, 0.607);   
}

.body .major{
    display: flex;
    flex-direction: row;
}

.sub1, .customCard{
    color: #0A283D; 
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 0.5rem;
    padding: 1rem 3rem;
    text-align: left;
    overflow-wrap: break-word;
}

.customCard{
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.customCard div{
    color: #0A283D;
    font-size: 1.6rem;
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: left;
}

.sub1 .name{
    font-size: 2rem;
    font-weight: 700;
    width: auto;
}

.sub1 .job{
    font-size: 1.7rem;
    width: auto;
}

.sub2 {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 0.5rem;
    padding: 1rem 3rem;
}

.avatar{
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1rem;
    color: white;
    margin-right: 2rem;
    border-radius: .3em;
    max-height: 3.8rem;
    width: fit-content;
    margin: 1rem;
}

.personalities{
    display: flex;
    flex-direction: row;
}

.btn{
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.btn button{
    width: 4rem;
    border-radius: 50%;
}

.hide {
    opacity: 0;
  }
      
.btn:hover > .hide {
    opacity: 1;
    font-size: 1.3rem;
    margin: 1rem;
    transition: all ease-in-out 0.5s;
}

@media screen and (max-width: 550px) {
    .body{
        display: flex;
        justify-content: space-between;
        width: 70%;
        margin: auto;
        margin-bottom: 0.5rem;
        margin-bottom: 1rem;
    }
    .sub1{
        display: flex;
        gap: 2rem;
        margin-left: 1rem;
        padding: 1rem 0;
        color: #741A36;
    }
    

    
    .avatar{
        font-size: 2rem;
        color: white;
        padding: 0.5rem;
        margin-top: 1rem ;
    }    
}