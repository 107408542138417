.slider {
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.749);
    display: block;
    border-radius: 10px;
    width: 70%;
    margin: auto;
    margin-top: 3rem;
}

.adjustbtn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin: auto;
}

.rightarrow {
    right: 120px;
    color: white;
    cursor: pointer;
    user-select: none;
    padding: 10px;
    font-size: 1.9rem;
    font-family: helvetica;
    font-weight: bold;
    z-index: 2;
    background-color: #182AA3;
    border: 2px solid #182AA3;
    border-radius: 36px;
    width: 140px;
    height: 47px;
    text-align: center;
    margin-bottom: 20px;
}

.donebutton {
    background-color: #182AA3;
    border: 2px solid #182AA3;
    color: white;
    cursor: pointer;
    user-select: none;
    padding: 10px;
    font-size: 1.9rem;
    font-family: helvetica;
    font-weight: bold;
    z-index: 2;
    border-radius: 36px;
    width: 140px;
    height: 47px;
    text-align: center;
    margin-bottom: 20px;
}

.donebutton:hover{
    transition-delay: 0.05s;
    background-color: #DCA500;
    border: 2px solid #DCA500;
}

.leftarrow {
    color: #0A283D;
    cursor: pointer;
    user-select: none;
    padding: 10px;
    font-size: 1.9rem;
    font-family: helvetica;
    font-weight: bold;
    z-index: 2;
    background-color: white;
    border: 2px solid #0A283C;
    border-radius: 36px;
    width: 140px;
    height: 47px;
    text-align: center;
    margin-bottom: 20px;
}

.leftarrow:hover, 
.rightarrow:hover{
    opacity: 0.9;
}

.bar {
    padding-top: 40px;
    padding-bottom: 20px;
    justify-content: center;
}
.progressbarC{
    position: relative;
    width: 58%;
    left: 21%;
    height: 35px;
    border: 2px solid #000735;
    border-bottom-left-radius: 150px;
    border-bottom-right-radius: 150px;
    border-top-left-radius: 150px;
    border-top-right-radius: 150px;
    text-align: center;
}

.progressbarC p{
    font-size: 1.9rem;
    font-family: helvetica ;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    z-index: 2;
}

.progressbar{
    position: absolute;
    height: 35px;
    border-bottom-left-radius: 150px;
    border-bottom-right-radius: 150px;
    border-top-left-radius: 150px;
    border-top-right-radius: 150px;
    background-color: #000735;
    background-image:repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    #131d6074 25px,
    #000735 20px
    );
    top:-2px;
    left: -3px;
    
}

.missing {
    color: rgb(192, 15, 15);
    font-size: 2rem;
}

.b{
    color: black;
}

.w{
    color: #f3f3f3;
}

.finish{
    display: block;
    color: white;
    cursor: pointer;
    user-select: none;
    padding: 10px;
    font-size: 1.9rem;
    font-family: helvetica;
    font-weight: bold;
    z-index: 2;
    background-color: #182AA3;
    border-radius: 36px;
    width: 140px;
    height: 47px;
    text-align: center;
    margin: 0 auto;
}

.f {
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.congrats
{
    border-radius: 200px;
}

.modalhead
{
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    height: 100px;
    background-image: linear-gradient(#1E2FA5,#0C1552);
}

.congratsbody
{
    background-size: cover;
    background-position: center;
    display: block;
    flex-direction: column;
    justify-content: center;
    gap:20px;
    height: 160px;
    margin: 0 15%;
    margin-bottom: 20px;
    z-index: -1;
}
.congratsbody1{
    background-image: url("../../Img/bg.png");
    position: relative;
    width: 100%;
    min-height: 100%;
    z-index: inherit;
    background-size: cover;
    background-position: center;
    opacity: 0.7;
}

.congratsbody2{
    flex-direction: column;
    justify-content: center;
    gap:40px;
    justify-content: center;
}

.checklogo
{
    display: flex;
    background-color: white;
    width: 140px;
    height: 140px;
    justify-content: center;
    align-items: center;
    border-radius: 80px;
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.349);
    margin: 0 auto;
    margin-top: -230px;
    margin-bottom: 10px;
}

.congratstext
{
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 4rem;
    font-weight:600;
}

.congratsdone
{
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.8rem;
    font-weight: 600;
}

.setupprofile
{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    user-select: none;
    background-color: #16289B;
    padding: 20px;
    font-size: 1.8rem;
    font-family: helvetica;
    font-weight: bold;
    z-index: 2;
    border-radius: 20px;
    width: fit-content;
    height: 50px;
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 70px;
}

.setupprofile:hover{
    background-color:#E79F27;
    opacity: 0.8;
    transition-delay: 0.05s;
}

.headicon{
    height: auto;
    margin: 0 auto;
}


@media screen and (max-width: 500px){
    .slider{
        width: 80%;
        left: 10%;
    }

    .leftarrow {
        left: 16px;
    }

    .rightarrow{
        right: 16px;
    }

    .progressbar{
        height: 30px;
    }

    .progressbarC{
        height: 30px;
        width: 80%;
        left: 10%;
    }

    .rightarrow {
        width: 80px;
        height: 30px;
        font-size: 1rem;
        padding: 5px;
    }

    .leftarrow {
        width: 80px;
        height: 30px;
        font-size: 1rem;
        padding: 5px;
    }

    .progressbarC p{
        font-size: 1rem;
    }
}
