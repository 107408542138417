
.mcq {
    display: flex;
    flex-direction: row;
    gap:3.5rem;
    justify-content: left;
    margin-left: 2%;
}

.mcq li {
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 50%;
    box-shadow: 0 0 5px rgb(128, 128, 128);
    border: 2px solid #0A1D96;
    display: flex;
    flex-direction: column;
    gap: 3.3rem;
    text-align: center;
}

.mcq li a {
    font-size: 2.2rem;
    font-family: Helvetica;
    font-weight: 600;
    display: block;
    padding: 1.1rem 0;
}

.mcq li label {
    padding-top: 5px;
    font-size: 1.4rem;
    font-weight: lighter;
    font-family: Segoe UI;
    color: black;
}

.mcq li:hover{
    background-color: #3B55FF;
    transition: all;
    transition-delay: 0.1s;
    cursor: pointer;
    color: white;
}

.active {
    background-color: #3B55FF;
    color: aliceblue;
}

@media screen and (max-width:500px) {
    .mcq {
        gap:2rem;
        margin: auto;
        width: 90%;
    }
    
    .mcq li {
        width: 3.5rem;
        height: 3.5rem;
        gap: 0.3rem;
    }
    
    .mcq li a {
        font-size: 1.2rem;
        padding: 1rem 0;
    }
    
    .mcq li label {
        font-size: 1.2rem;
    }
}