.congrats {
  border-radius: 200px;
}

.modalhead {
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  height: 100px;
  background-image: linear-gradient(#1e2fa5, #0c1552);
}

.congratsbody {
  background-size: cover;
  background-position: center;
  display: block;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  height: 160px;
  margin: 0 15%;
  margin-bottom: 20px;
  z-index: -1;
}
.congratsbody1 {
  background-image: url("../../Img/bg.png");
  position: relative;
  width: 100%;
  min-height: 100%;
  z-index: inherit;
  background-size: cover;
  background-position: center;
  opacity: 0.7;
}

.congratsbody2 {
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  justify-content: center;
}

.checklogo {
  display: flex;
  background-color: white;
  width: 140px;
  height: 140px;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.349);
  margin: 0 auto;
  margin-top: -230px;
  margin-bottom: 10px;
}

.congratstext {
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 4rem;
  font-weight: 600;
}

.congratsdone {
  width: 70%;
  margin: auto;
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
}

.setupprofile {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  user-select: none;
  background-color: #16289b;
  padding: 20px;
  font-size: 1.8rem;
  font-family: helvetica;
  font-weight: bold;
  z-index: 2;
  border-radius: 20px;
  width: fit-content;
  height: 50px;
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 70px;
}

.setupprofile:hover {
  background-color: #e79f27;
  opacity: 0.8;
  transition-delay: 0.05s;
}

.headicon {
  height: auto;
  margin: 0 auto;
}

@media screen and (max-width: 750px) {
  .congratstext {
    font-size: 2.5rem;
  }
  .congratsdone {
    font-size: 1.5rem;
    padding: 1rem;
  }
}
