.comp{
    width: 90%;
    margin: auto;
    padding-bottom: 5rem;
    margin-top: 5rem;
    text-align: center;
    box-shadow: 0 0 10px rgba(225, 225, 225);
    z-index: 4;
    background-color: rgba(255, 255, 255,0.8);
}
.titlea{
    color: rgb(6, 40, 61);
    font-size: 8rem;
    font-weight: 500;
    text-transform: capitalize;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 2rem;
}
.comp .subdis{
    width: 80%;
    margin: auto;
    margin-bottom: 5rem;
}
.comp .info{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
}
.comp .left{
    width: 40%;
    margin-top: 2rem;
    min-width: 300px;
}
.comp .right {
    width: 40%;
    min-width: 300px;
}
.comp .right img{
    max-width: 100%;
}
.subdis p{
    font-size: 1.9rem;
    line-height: 1.8;
}

.left p{
    font-size: 1.7rem;
    text-align: left;
    margin: 0;
    line-height: 1.4;
}
.cube{
    display: flex;
    position: relative;
    top: -350px;
    left: 0;
}

.cube li{
    position: relative;
    border: 2mm solid #0040C1;
    width: 150px;
    height: 150px;
    top: 0px;
    left: 0px;
    z-index: -1;
    opacity: 0.2;
    overflow: hidden;
    animation: animate 15s linear infinite;
}
.cube li:nth-child(1)
{
    width: 30px;
    height: 30px;
    left: 1200px;
}
.cube li:nth-child(2)
{
    top: -200px;
    left: 269px;
}
.cube li:nth-child(3){
    width: 100px;
    height: 100px;
    left: 50px;
}
@keyframes animate {
    0%   {opacity: 0;
        transform: translateY(0) rotate(0deg);}
    25%  {opacity: 0.4;}
    50%  {opacity: 0.7;}
    75%  {opacity: 0.4;}
    100% {opacity: 0.3; transform: translateY(-800px) rotate(720deg);}
  }


@media  screen and (max-width:870px) {
    .cube li:nth-child(1){
        left: 300px;
    }
    .cube li:nth-child(2){
        display: none;
    }
   .comp .info {
    display: flex;
    flex-direction: column-reverse;
    
   }
   .comp .info .left,
   .comp .info .right {
    margin: 0;
    width: 90%;
   }
}