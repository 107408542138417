.emptyprofile
{
    width: 80%;

}

.badge
{
    color: #1A2C9D;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 8%;
}

.badge:hover
{
    color: #3B55FF;
}

.nodatapic{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.nodatapic img{
    max-width: 50%;
    max-height: 50%;
}

.noinfo
{
    display: flex;
    justify-content: center;
    font-size: 42px;
    font-weight: bold;
    color: #0A283D;
    margin-bottom: 10px;
}

.setupprofile
{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    user-select: none;
    background-color: #16289B;
    padding: 20px;
    font-size: 18px;
    font-family: helvetica;
    font-weight: bold;
    z-index: 2;
    border-radius: 20px;
    width: fit-content;
    height: 50px;
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
}

.setupprofile:hover{
    background-color:#E79F27;
    opacity: 0.8;
    transition-delay: 0.05s;
}

.noinfo{
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: Helvetica;
}

.noinfo h5{
    font-size: 25px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}