.fullprofile
{
    width: 80%;
    height: fit-content;
    margin: auto;
}

.maininfo
{
    color: #0a283d;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    max-height: fit-content;
    position: relative;
    display: block;
    top: 5%;
    border-radius: 10px;
    padding-inline: 1rem;
}

.userinfo
{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6%;
    margin-bottom: 5%;
    min-height: fit-content;
    padding: 1% 3% 1% 12%; 
    background-color: #F1F1F1;
}

.userinfo h2{
    font-size: 6rem;
    font-family: 'Segoe UI';
    font-weight: 700;
    color: #0A283D;
}

.imgholder {
    width: 20.0rem;
    height: 26.5rem;
}

.imgholder img{
    width: 100%;
    height: 100%;
    border-radius: 41px;
    box-shadow: 4px 8px 10px rgb(0, 0, 0,0.4);
}

.badge
{
    color: #1A2C9D;
    margin-bottom: 8%;
}

.badge:hover
{
    color: #3B55FF;
}

.des{
    width: 100%;
    box-shadow: 4px 8px 10px rgb(0, 0, 0,0.2);
    box-sizing: border-box;
    padding: 10%;
    padding-top: 0;
    padding-bottom: 5%;
    margin-bottom: 2%;
    border-radius: 10px;
}

.des h3{
    text-align: left;
    padding-bottom: 2%;
}

.des p{
    font-family: 'Segoe UI';
    font-size: 1.8rem;
    padding: 2%;
}

.percentages {
    width:50%;
    background-color: #F1F1F1;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-block: 2rem;
    padding-left: 1rem;
}

.percentages h4{
    font-size:3rem;
    color: black;
    margin-bottom: 5%;
    padding-top: 5%;
}

.perc{
    display: flex;
    flex-direction: row;
}

.avatarnaming{
    display: flex;
    flex-direction: column;
}

@media screen and (max-width:500px) {
    .userinfo
    {
        display: flex;
        flex-direction: column;
        background-color: #F1F1F1;
        width: 100%;
        margin-left: -20px;
    }
    
    .des p{
        font-size: 1.7rem;
    }

    .userinfo h2{
        padding-top: 2%;
        padding-bottom: 5%;
        font-size: 4.5rem;
    }
    
    .percentages {
        width:90%;
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-bottom: 5%;
    }
    
    .percentages h4{
        font-size:2.5rem;
        margin-bottom: 5%;
        padding-top: 5%;
    }
    
    .perc{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .des h3{
        font-size: 2.4rem;
    }
}

