.body {
    position: absolute;
    width:100%;
    background-image: linear-gradient(
        to right bottom ,
        rgba(0, 1, 5, 0.884),
        rgba(6, 54, 143, 0.897));
    background-size: cover;
    background-position: center;
    padding-bottom: 20vh;
}
.shape{
    justify-content: center;
    align-items: center;
    width: 90%;
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.749);
    border-radius: 10px;
    margin: auto;
}

.head {
    width: 70%;
    margin: auto;
    margin-top: 6rem;
    margin-bottom: 6rem;
    overflow-x: hidden;
}

.head .fimg {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap:0;
    justify-content: center;
    padding-top: 5rem;
}

.head .fimg li{
    width:30rem;
    margin-bottom: 1rem;
}

.head .Limg{
    width: 50rem;
    margin: auto;
}

.head .Limg img{
    width: 100%;
    height: 100%;
}

.head .fimg li img{
    width: 100%;
}

.head .Cimg{
    width: 250px;
    margin: auto;
    padding-top: 5rem;
}
.head .Cimg img{
    width: 100%;
}
.head h3{
    margin-top: 5rem;
    font-style:Helvetica;
    font-size: 1.9rem;
    color: #0A283D;
}

.head h2{
    font-style:Helvetica;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 800;
    color: #0A283D;
}

.shape .userbox{
    margin-top: 2rem;
    max-width: 90%;
    margin: auto;
    display: flex;
    box-shadow: -5px 5px 5px 1px #00000027;
    flex-wrap: wrap;
    overflow-x:hidden;
}

.userbox .left{
    width:18%;
    margin: 7rem auto;
    text-align: center;
}

.userbox .left h1{
    color:#0A283D;
    font-family: Helvetica, sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
}

.userbox .right{
    width: 80%;
    margin: 2rem auto;
    padding: 4rem;
    display: flex;
    flex-direction: row;
    gap: 3rem;
    flex-wrap:wrap;
    border-left: 1.2mm solid #0A283D;
    border-radius: 1px 1px 1px 1px;
}

.userbox .right .inputbox{
    display: flex;
    flex-direction: column;
    height: fit-content;
}
.input {
    width: 25.5rem;
    box-sizing: border-box;
    border: 2px solid #747474;
    border-radius: 4px;
    font-size: 1.4rem;
    background-repeat: no-repeat;
    padding: 1rem 0.8rem;
    text-align: left;
    background-color: #F1F1F1;
    font-family: Arial, Helvetica, sans-serif;
}

.inputS {
    width: 50rem;
    box-sizing: border-box;
    border: 2px solid #747474;
    border-radius: 4px;
    font-size: 1.4rem;
    background-repeat: no-repeat;
    padding: 1rem 0.8rem;
    text-align: left;
    background-color: #F1F1F1;
    font-family: Arial, Helvetica, sans-serif;
    resize: none;
}

.inputL {
    width: 25.5rem;
    box-sizing: border-box;
    border: 2px solid rgba(116, 116, 116, 0.849);
    border-radius: 4px;
    font-size: 1.4rem;
    background-repeat: no-repeat;
    padding: 12px 8px;
    text-align: left;
    background-color: #F1F1F1;
    margin-top: 5px;
    resize: none;
    font-family: Arial, Helvetica, sans-serif;
}

.inputJ{
    width: 30.0rem;
    box-sizing: border-box;
    border: 2px solid rgba(116, 116, 116, 0.849);
    border-radius: 4px;
    font-size: 1.3rem;
    background-repeat: no-repeat;
    padding: 1.2rem 0.8rem;
    text-align: left;
    background-color: #F1F1F1;
    margin-top: 5px;
    resize: none;
    font-family: Arial, Helvetica, sans-serif;
}


.job{
    width:100%;
    text-align: left;
    font-size: 2.2rem;
    margin-bottom: 0;
    margin: auto;
    color: #0A283D;
}

.userbox .right .label {
    padding: 2px 0;
    font-size: 14px;
    color: black;
    pointer-events: none;
    transition: .5s;
}

.labeladd{
    padding: 2px 2px;
    font-size: 14px;
    color: #ACA5A5;
    pointer-events: none;
    transition: .5s;
}

button {
    background-color: #182AA3;
    border: 2px solid #182AA3;
    color: white;
    cursor: pointer;
    user-select: none;
    padding: 10px;
    font-size: 19px;
    font-family: helvetica;
    font-weight: bold;
    z-index: 2;
    border-radius: 36px;
    width: 140px;
    height: 47px;
    text-align: center;
}

button:hover{
    transition-delay: 0.05s;
    background-color: #DCA500;
    border: 2px solid #DCA500;
    cursor: pointer;
}

.Err{
    margin-top: 5px;
    color: red;
}

.recieve{
    font-family: Arial, Helvetica, sans-serif;
    margin: auto;
}

.date{
    display: flex;
    flex-direction: column;
}

.sedate{
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.f {
    width: 100%;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 5rem;
}

.arrayConatiner{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
}

.arrayConatiner2 button{
    color: #3B55FF;
    background-color:#F1F1F1;
    border: none;
    width: 3.5rem;
    height: 3.5rem;
    margin: 1rem;
    border-radius: 5px;
    padding: 0;
    box-shadow: 1px 1px 5px rgb(0, 0, 0,0.2);
    border: 1px solid #0A283D;
}

.arrayConatiner .remove{
    color: #3B55FF;
    background-color:#F1F1F1;
    border: none;
    width: 3.5rem;
    height: 3.5rem;
    margin: 1rem;
    border-radius: 5px;
    padding: 0;
    box-shadow: 1px 1px 5px rgb(0, 0, 0,0.2);
    border: 1px solid #0A283D;
}

.arrayConatiner .add{
    background-color:#F1F1F1;
    width: 16rem;
    height: 16rem;
    border-radius: 5px;
    padding: 0;
    font-size: 12rem;
    font-weight: 300;
    color: #3B55FF;
    box-shadow: 0 0 5px rgb(0, 0, 0,0.2);
    border: none;
}

.remove:hover{
    border: 1px solid #3B55FF;
}

.arrayConatiner .inputbox {
    margin-right: 20px ;
    
}
.arrayConatiner .contFix  {
    text-align: center;
}
.arrayConatiner .contFix label  {
    text-align: left;
}

.update{
    width: 100%;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 5rem;
    margin-top: 2rem;
}

@media screen and (max-width:500px) {
    .shape .userbox{
        flex-direction: column;
    }

    .head {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .head h3{
        margin-top: 1rem;
        font-size: 1.5rem;
    }

    .userbox .left{
        width: 70%;
        margin: 3rem auto;
        margin-top: 5rem;
    }

    .userbox .right{
        border: none;
        width: 90%;
        margin: auto;
        padding-top: 0;
    }

    .inputS{
        width: 25rem;
    }

    .arrayConatiner{
        flex-direction: column;
    }

    .arrayConatiner .add{
        width: 10rem;
        height: 10rem;
        font-size: 6rem;
    }

    .contFix{
        justify-content: center;
    }

    .inputJ{
        width: 25rem;
    }

    .head .Limg{
        width: 25rem;
    }

    .head h2{
        font-size: 1.8rem;
    }

    .f button{
        width: 20%;
        font-size: 1.5rem;
    }

    .head .Cimg{
        width: 150px;
    }
}