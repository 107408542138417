.backDrop{
    background-color: rgba(0, 0, 0, 0.597);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 800;
}
.pop {
    background-color: white;
    width: 50%;
    min-width: 300px;
    margin: auto;
    padding: 2rem;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    border-radius: 10px;
}
.pop h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
}

.pop button {
   
    color: white;
    height: 40px;
    border: none;
    padding: 1rem;
    font-size: 2rem;
    border-radius: 10px;
    background-color:#07336c;
    border: none;
    transition: .5s;
}
.pop button:hover {
    background-color: #0d458f;
}




.addpic{
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
}

.addpicbuttons{
    margin-top: 2rem;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.pop .cancel{
    background-color: rgb(157, 21, 21);
}

.pop .cancel:hover{
    background-color: rgb(197, 32, 32);
}

.bar{
    width: 60%;
    margin: auto;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    position: relative;
}

.loader{
    display: flex;
    justify-content: center;
}
