.certificates
{
    width: 100%;
}


.title
{
    display: flex;
    align-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.7rem;
    font-weight: normal;
    gap: 5px;
    margin-bottom: 10px;
}

.body
{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    word-wrap: break-word;
    margin-left: 20px;
    margin-bottom: 15%;
}