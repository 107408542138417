.Profile
{
    display: flex;
    flex-direction: row;
}

.viewProfile
{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
}

.up{
    display: flex;
    justify-content: center;
    align-items: center;
}
.up:hover{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: white;
}

.title{
    width: 60%;
    margin: auto;
    text-align: center;
    font-family: Helvetica, sans-serif;
    font-size: 5rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
    color: #0A283D;
    padding: 2rem;
    background-color: rgba(240, 248, 255, 0.144);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
    /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
}