.certificates
{
    width: 100%;
    margin-left: 5%;
    margin-top: 3%;
    margin-bottom: 7%;
    
}

.maintitle
{
    display: flex;
    justify-content: space-between;
}

.duration
{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    width: 45%;


}

.title
{
    display: flex;
    align-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.7rem;
    font-weight: normal;
    gap: 5px;
    margin-bottom: 10px;
}

.body
{
    display: flex;
    flex-direction: column;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    word-wrap: break-word;
    margin-left: 20px;
    width: 30%;

}
@media  screen and (max-width:470px) {
  
.maintitle
{
   flex-direction: column;
   margin-bottom: 2rem;
}

 }